import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="">
      <header className="bg-gradient-to-r from-blue-200 to-cyan-200 flex justify-center flex-col items-center h-screen space-y-2">
        <img src={logo} className="App-logo md:w-80 w-60" alt="logo" />
        <p>
          <code className='text-2xl'>Coming soon...</code>
        </p>
    
      </header>
    </div>
  );
}

export default App;
